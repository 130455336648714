import { post } from '@/helpers/request'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  createEvent ({ event, data }) {
    return post(`${baseUrl}/events`, {
      data: {
        attributes: {
          action: event,
          metadata: data
        }
      }
    })
  }
}
