<template>
  <signup-template class="search-steps" :progress-amount="progressAmount" data-test="layout">
    <template #text>
      <!-- eslint-disable vue/no-v-html -->
      <h1
        class="search-steps__title"
        data-test="title"
        v-html="$t(`${langPath}.title.${stepTitle}`)"
      />
      <!-- eslint-enable vue/no-v-html -->
      <img
        v-if="isLastStep"
        src="@/assets/images/signup/first-search.png"
        class="search-steps__image"
      >
      <ev-link
        v-if="isLastStep"
        class="redirect-button"
        :a-href="searchUrl"
        data-test="redirect-button"
      >
        {{ $t(`${langPath}.redirectButtonText`) }}
      </ev-link>
    </template>
  </signup-template>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import EvLink from '@revelotech/everestV2/EvLink'
import SignupTemplate from './components/SignupTemplate'
import events from '@/repository/companies-app/events'
import { SIGNUP } from '@/store/namespaces'

const signUpHelper = createNamespacedHelpers(SIGNUP)
const stepsConfig = {
  1: { title: 'performingAnalysis', progressAmount: 60 },
  2: { title: 'filtering', progressAmount: 80 },
  3: { title: 'checkingAvailability', progressAmount: 90 },
  4: { title: 'results', progressAmount: 100 }
}

export default {
  name: 'SignupSearchSteps',
  components: {
    EvLink,
    SignupTemplate
  },
  data () {
    return {
      langPath: __langpath,
      step: 1
    }
  },
  computed: {
    ...signUpHelper.mapState(['searchUrl', 'searchParams']),
    stepTitle () {
      return stepsConfig[this.step]?.title || 'results'
    },
    progressAmount () {
      return stepsConfig[this.step]?.progressAmount || 100
    },
    isLastStep () {
      return this.step === 4
    }
  },
  mounted () {
    if (Object.keys(this.searchParams).length === 0) {
      return this.$router.push({ name: 'SignupSearchFilter' })
    }

    this.getSearchUrl(this.searchParams)
    events.createEvent({ event: 'search_results', data: {} })

    setTimeout(() => { this.step = 1 }, 1)
    setTimeout(() => { this.step = 2 }, 1000)
    setTimeout(() => { this.step = 3 }, 3000)
    setTimeout(() => { this.step = 4 }, 5000)
  },
  methods: {
    ...signUpHelper.mapActions(['getSearchUrl'])
  }
}
</script>

<style lang="scss">
.search-steps {
  &__image {
    @include margin(bottom, 10);
    @include margin(top, 10);

    display: block;
  }

  &__title {
    @extend %h4;

    @include margin(bottom, 4);

    color: var(--tx-neutral-blank);
  }

  .redirect-button {
    display: inline;
    width: initial;
  }
}

.signup-template {
  @media (max-width: $container-lg-max-width) {
    &__item--padded {
      align-items: center;
      display: flex;
      height: inherit;
    }

    &__item {
      padding: 0;
    }
  }

  &__item {
    background-color: var(--bg-blue);
  }
}
</style>
